import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import Dashboard from '../pages/Dashboard'
import EnrollmentsPage from '../pages/EnrollmentsPage'

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePath.DASHBOARD} Component={Dashboard} />
      <Route path={RoutePath.ENROLLMENTS} Component={EnrollmentsPage} />
      <Route path="*" element={<Navigate to={RoutePath.DASHBOARD} />} />
    </Routes>
  )
}

export default PrivateRoutes
