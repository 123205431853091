import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Snackbar, Button, IconButton, Menu, MenuItem } from '@mui/material'
import Box from '../components/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import moneyIcon from '../resources/images/Money.png' // Adjust the path as necessary
import EmptyStateImg from '../resources/images/EnrollmentEmpty.png' // Adjust the path as necessary
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { ReactComponent as PaymentNotDoneIcon } from '../resources/svg/lucide_circle-check.svg' // Adjust the path as necessary
import { ReactComponent as PaymentDoneIcon } from '../resources/svg/lucide_circle-check_green.svg' // Adjust the path as necessary

import MoreVert from '@mui/icons-material/MoreVert'
import Checkbox from '@mui/material/Checkbox'
import DashboardCard from '../components/DashboardCard'
import useAppState from '../hooks/useAppState'
import { getEnrollmentsForClient, sendEnrollmentEmail, upsertEnrollment } from '../libs/CloudFunctionsApiHandler'
import { Enrollment } from '../../../pure-js/types/GrooverTypes'
import userIcon from '../resources/images/User.png' // Adjust the path as necessary
import '../resources/css/Dashboard.css'
import '../resources/css/EnrollmentsList.css'
import {
  formatPayment,
  getAllEnrollmentsCount,
  getAllPaidRevenueForRegistration,
  getAllRevenueForRegistration,
  getEnrollmentPrice,
  getPaidRegistrationRatio,
  isEnrollmentPaid
} from '../utils/enrollmentUtils'
import dayjs from 'dayjs'

const EnrollmentsPage: React.FC = () => {
  const [enrollments, setEnrollments] = useState<Enrollment[]>([])
  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRow, setSelectedRow] = useState<Enrollment | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [allSelected, setAllSelected] = useState(false)

  const navigate = useNavigate()
  const state = useAppState()

  const { activityId, clientId } = useParams<{ activityId: string; clientId: string }>()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: Enrollment) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSelectedRow(null)
  }

  const handleApproveAttendee = () => {
    if (selectedRow) {
      updateAttendeeStatus('confirmed')
      handleMenuClose()
    }
  }

  const updateAttendeeStatus = async (newStatus: 'pending' | 'confirmed' | 'active' | 'cancelled' | 'deleted') => {
    if (selectedRow) {
      setLoading(true)
      handleMenuClose()
      selectedRow.status = newStatus

      if (newStatus === 'confirmed') {
        const response = await sendEnrollmentEmail(
          { enrollmentId: selectedRow.id, type: 'registration_approved' },
          state.state
        )
        if (!response.success) {
          console.error('Payment confirmed email error', response)
          return // don't upsert enrollment with new status
        }
      }

      // Call the upsertEnrollment function to save the changes
      saveEnrollment(selectedRow)

      // Show the Snackbar
      setSnackbarOpen(true)

      // Remove the row from enrollments if status is 'deleted'
      if (newStatus === 'deleted') {
        setEnrollments((prevEnrollments) => prevEnrollments.filter((enrollment) => enrollment.id !== selectedRow.id))
      }

      setLoading(false)
    }
  }

  const saveEnrollment = (enrollment: Enrollment) => {
    // Call the upsertEnrollment function to save the changes
    upsertEnrollment(enrollment, state.state)
      .then((response) => console.log('Enrollment status updated successfully', response))
      .catch((error) => {
        console.error('Error updating enrollment status', error)
      })
  }

  const handleRowSelect = (id: string) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id) ? prevSelectedRows.filter((rowId) => rowId !== id) : [...prevSelectedRows, id]
    )
  }

  const handleSelectAllRows = () => {
    if (allSelected) {
      setSelectedRows([])
    } else {
      setSelectedRows(enrollments.map((registration) => registration.id))
    }
    setAllSelected(!allSelected)
  }
  const approveSelectedEnrollments = () => {
    selectedRows.forEach((id) => {
      const attendee = enrollments.find((registration) => registration.id === id)
      if (attendee) {
        updateAttendeeStatus('confirmed')
      }
    })
  }

  const handleApprovePayment = async (enrollment, isMainUser: boolean, status: string) => {
    console.log('Updating payment status:', enrollment, status)

    // changing into pending means refunding (in alpha)
    const isRefund = status == 'pending'
    const price = isRefund ? -getEnrollmentPrice(enrollment) : getEnrollmentPrice(enrollment)

    if (!enrollment.payments) enrollment.payments = []
    // add manual payment/refund
    enrollment.payments.push({
      paymentDate: dayjs().toISOString(),
      currency: 'SEK',
      totalAmount: price,
      paymentMethod: 'manual',
      source: 'manual',
      type: isRefund ? 'refund' : 'pay'
    })

    // Update the courseUserRegistrations state with the updated attendee
    setEnrollments((prevRegistrations) =>
      prevRegistrations.map((registration) =>
        registration.id === enrollment.id ? { ...registration, payments: [...enrollment.payments] } : registration
      )
    )

    // Show the Snackbar
    setSnackbarOpen(true)

    if (status === 'confirmed') {
      console.log('Sending payment confirmed email for enrollment:', enrollment.id)
      const response = await sendEnrollmentEmail(
        { enrollmentId: enrollment.id, type: 'payment_confirmed' },
        state.state
      )
      if (!response.success) {
        console.error('Payment confirmed email error', response)
        return // don't upsert enrollment with new status
      }
    }

    // Call the upsertEnrollment function to save the changes
    upsertEnrollment(enrollment, state.state)
      .then((response) => console.log('Payment status updated successfully', response))
      .catch((error) => console.error('Error updating payment status', error))
  }

  const handleApprovePaymentAndCloseMenu = (status: string) => {
    if (selectedRow) {
      handleApprovePayment(selectedRow, true, status)
      handleMenuClose()
    }
  }

  // Get all course user registrations by registration id
  useEffect(() => {
    const fetchCourseUserRegistrations = async () => {
      try {
        if (!clientId) {
          throw new Error('Client ID is undefined')
        }
        const req = { clientId: clientId } // Replace with actual registration ID and customer ID
        const enrollments = await getEnrollmentsForClient(req, state.state)
        const filteredEnrollments = enrollments
          .filter((enrollment) => enrollment.activities.some((activity) => activity.id === activityId))
          .filter((enrollment) => enrollment.status !== 'deleted')
        setEnrollments(filteredEnrollments)

        setLoading(false)
      } catch (err) {
        console.error('Error fetching course user registrations:', err)
        setLoading(false)
      }
    }

    fetchCourseUserRegistrations()
  }, [state.state, clientId, activityId])

  const handleReturn = () => {
    navigate(-1) // Navigate back to the previous page
  }

  return (
    <div className="mainContainer">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message="Update successful"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <div className="PageContainer">
        <div className="dashboard-container">
          <ArrowBackIcon onClick={handleReturn} style={{ cursor: 'pointer' }} />
          {loading ? (
            <div className="loading">
              <div className="spinner"></div>
            </div>
          ) : enrollments.length === 0 ? (
            <div className="no-registrations">
              <img src={EmptyStateImg} alt="No Registrations" className="empty-state-img" />
              <p>Inga anmälningar än</p>
            </div>
          ) : (
            <>
              <div className="dashboard-cards">
                <DashboardCard
                  icon={<img src={userIcon} alt="User Icon" />}
                  title="Anmälningar"
                  value={getAllEnrollmentsCount(enrollments)}
                />
                <DashboardCard
                  icon={<img src={userIcon} alt="User Icon" />}
                  title="Kursvärde"
                  value={getAllRevenueForRegistration(enrollments, true) + ' SEK'}
                  subtitle={`Inkl. ej godkända: ${getAllRevenueForRegistration(enrollments, false)} SEK`}
                />
                <DashboardCard
                  icon={<img src={moneyIcon} alt="Money Icon" />}
                  title="Betalda"
                  value={`${getAllPaidRevenueForRegistration(enrollments)} SEK`}
                  subtitle={`${getPaidRegistrationRatio(enrollments)}`}
                />
              </div>
              <div className="attendee-list">
                <div className="header">
                  <div className="cell">
                    <Checkbox
                      style={{ color: 'white', margin: 0, padding: '0 10px' }}
                      checked={allSelected}
                      onChange={handleSelectAllRows}
                    />
                  </div>
                  <div className="cell">Datum</div>
                  <div className="cell">Namn</div>
                  <div className="cell">Email</div>
                  <div className="cell">Typ</div>
                  <div className="cell">Telefon</div>
                  <div className="cell">Godkännande</div>
                  <div className="cell">Betalning</div>
                  <div className="cell"></div>
                </div>
                {enrollments
                  .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                  .flatMap((enrollment, index) => {
                    const mainUserRow = (
                      <div className="row row-group" key={`${index}-main`}>
                        <Checkbox
                          checked={selectedRows.includes(enrollment.id)}
                          onChange={() => handleRowSelect(enrollment.id)}
                          style={{ color: 'white' }}
                        />
                        <div className="cell">
                          {new Date(enrollment.createdAt).toLocaleDateString('sv-SE', {
                            day: 'numeric',
                            month: 'numeric'
                          })}
                        </div>
                        <div className="cell">{enrollment.mainUser.name}</div>
                        <div className="cell">{enrollment.mainUser.email}</div>
                        <div className="cell">{enrollment.mainUser.type === 'leader' ? 'L' : 'F'}</div>
                        <div className="cell">{enrollment.mainUser.phone}</div>
                        <div className={`cell status ${enrollment.status.toLowerCase()}`}>
                          {enrollment.status === 'pending' ? 'Ej godkänd' : 'Godkänd'}
                        </div>
                        <div className="cell payment">
                          {enrollment.status === 'confirmed' && !isEnrollmentPaid(enrollment) && (
                            <div
                              onClick={() => handleApprovePayment(enrollment, true, 'confirmed')}
                              style={{ cursor: 'pointer' }}
                              title="Klicka för att registrera betalning"
                            >
                              <RadioButtonUncheckedIcon style={{ fontSize: '1rem' }} />{' '}
                              {' ' + formatPayment(getEnrollmentPrice(enrollment)) + ' SEK'}
                            </div>
                          )}
                          {isEnrollmentPaid(enrollment) && (
                            <div>
                              <PaymentDoneIcon /> {formatPayment(getEnrollmentPrice(enrollment))}
                              {' SEK'}
                            </div>
                          )}
                        </div>
                        <div className="cell">
                          <IconButton onClick={(event) => handleMenuOpen(event, enrollment)} style={{ margin: '2px' }}>
                            <MoreVert style={{ color: 'white' }} />
                          </IconButton>
                        </div>
                      </div>
                    )

                    const partnerUserRow = enrollment.partnerUser ? (
                      <div className="row partner-row" key={`${index}-partner`}>
                        <div className="cell"></div>
                        <div className="cell"></div>
                        <div className="cell">{enrollment.partnerUser.name}</div>
                        <div className="cell">{enrollment.partnerUser.email}</div>
                        <div className="cell">{enrollment.partnerUser.type === 'leader' ? 'L' : 'F'}</div>
                        <div className="cell">{enrollment.partnerUser.phone}</div>
                        <div className={`cell status ${enrollment.status.toLowerCase()}`}>
                          {enrollment.status === 'pending' ? 'Ej godkänd' : 'Godkänd'}
                        </div>
                        <div className="cell payment"></div> {/* No payment for partner user */}
                        <div className="cell"></div>
                      </div>
                    ) : null

                    return partnerUserRow ? [mainUserRow, partnerUserRow] : [mainUserRow]
                  })}
              </div>
              <Box direction="row" gap="12px">
                <Button
                  className="custom-button"
                  onClick={approveSelectedEnrollments}
                  disabled={selectedRows.length === 0}
                >
                  Godkänn anmälningar
                </Button>
                {/* <Button className="custom-button" onClick={handleShowSelectedRows} disabled={selectedRows.length === 0}>
                  Exportera till csv
                </Button> */}
              </Box>
            </>
          )}
        </div>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} classes={{ paper: 'menu-custom' }} onClose={handleMenuClose}>
        {selectedRow && (
          <>
            {selectedRow.status !== 'confirmed' && (
              <MenuItem onClick={() => handleApproveAttendee()}>
                <PaymentDoneIcon style={{ marginRight: '8px' }} />
                Godkänn deltagare
              </MenuItem>
            )}
            {selectedRow.status === 'confirmed' && !isEnrollmentPaid(selectedRow) && (
              <MenuItem onClick={() => handleApprovePaymentAndCloseMenu('confirmed')}>
                <PaymentDoneIcon style={{ marginRight: '8px' }} />
                Markera som betald
              </MenuItem>
            )}
            {isEnrollmentPaid(selectedRow) && (
              <MenuItem onClick={() => handleApprovePaymentAndCloseMenu('pending')}>
                <PaymentNotDoneIcon style={{ marginRight: '8px', color: 'red' }} />
                Markera som obetald
              </MenuItem>
            )}
            <div style={{ borderTop: '1px solid lightgray', margin: '8px 0' }}></div>
            <MenuItem onClick={() => updateAttendeeStatus('deleted')} style={{ color: 'red' }}>
              <CloseIcon />
              Radera anmälning
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  )
}

export default EnrollmentsPage
