import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { Images } from '../../../pure-js/libs/Images'
import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import config from '../Config'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import Box from './Box'
import ContinueWithButton from './ContinueWithButton'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Layout } from './Layout'
import { SignInLandingLogo } from './SignInLandingCourselyLogo'

export default function SignInLanding(props: SignInViewProps) {
  return (
    <Layout>
      <Box fullWidth align="center" className="mainContainerLogin">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth align="center" right left spacing={reallyBigSpacing}>
            <Box top spacing={smallSpacing}>
              <FigmaText textKey={DeprecatedTexts.onboardingStartPageLoginPageSectionHeader} />
            </Box>
            {config.enableGoogleLogin && (
              <Box top spacing={smallSpacing} fullWidth>
                <ContinueWithButton
                  onLogin={props.onLoginWithGoogle}
                  // imageKey={Images.googleIcon}
                  textKey={DeprecatedTexts.onboardingOtpPageCtaPrimaryVerifyCode}
                  text="Continue with Google"
                />
              </Box>
            )}
            <Box top spacing={smallSpacing} />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`
