import { DeprecatedTexts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
/*
import location from '../../../assets/images/location.svg'
import calendar from '../../../assets/images/calendar.svg'
import swish from '../../../assets/images/swish.png'
import visa from '../../../assets/images/visa.png'
import applePay from '../../../assets/images/applePay.png'
*/
import styled from '@emotion/styled'
import { Controller } from 'react-hook-form'
import { useForm } from '../hooks/useForm'
import * as Yup from 'yup'
import { RequiredTextValidation } from '../libs/ValidationHelper'
import { TextFieldWithLabelForm } from './TextFieldWithLabelForm'
import { BorderRadixes } from '../enums/BorderRadixes'
import { useState } from 'react'
import useResponsive from '../hooks/useResponsive'

const PhoneRegExp = /^\+?\d{1,3}\d{7,10}$/

type CourseRegistrationFormValues = {
  name: string
  phoneNumber: string
  email: string
  role: 'leader' | 'follower'
  partnerName: string
  partnerPhoneNumber: string
  partnerEmail: string
  partnerRole: string
  discountCode?: string
}

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation('Enter Name'),
  phoneNumber: Yup.string().matches(PhoneRegExp, 'Phone Number is NOT valid').required('Phone Number is required'),
  email: Yup.string().required(),
  role: Yup.string().required(),
  partnerName: RequiredTextValidation('Enter Name'),
  partnerPhoneNumber: Yup.string()
    .matches(PhoneRegExp, 'Phone Number is NOT valid')
    .required('Phone Number is required'),
  partnerEmail: Yup.string().required(),
  partnerRole: Yup.string().required(),
  discountCode: Yup.string()
})

const CourseRegistrationForm = () => {
  const [isPartnerSelected, setIsPartnerSelected] = useState(false)
  const [attendeeRole, setAttendeeRole] = useState<'LEADER' | 'FOLLOWER' | ''>('')
  const [partnerRole, setPartnerRole] = useState<'LEADER' | 'FOLLOWER' | ''>('')
  const [paymentMethodType, setPaymentMethodType] = useState<'SWISH' | 'CARD' | 'APPLE_PAY'>('CARD')
  const { isMobile } = useResponsive()

  const formProps = useForm<CourseRegistrationFormValues>(ValidationSchema, {
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      role: 'leader',
      partnerName: '',
      partnerPhoneNumber: '',
      partnerEmail: '',
      partnerRole: 'follower',
      discountCode: ''
    }
  })

  const onSubmit = (values: CourseRegistrationFormValues) => {
    console.log(values)
  }

  return (
    <Container fullWidth align="center">
      <FormContainer width={isMobile ? '400px' : '100%'}>
        <Box fullWidth top left bottom spacing={'25px'}>
          <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Salsa Nybörjare Helgworkshop" />
          <Box fullWidth direction="row" align="center" top spacing={'17px'} gap="6px">
            {/**  <img src={location} width={9} height={11} /> **/}
            <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Göteborg" />
          </Box>
          <Box fullWidth direction="row" align="center" justify="space-between">
            <Box direction="row" align="center" gap="6px">
              {/**  <img src={calendar} width={9} height={11} /> **/}
              <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Lör 29 Okt - Sön 30 Okt" />
            </Box>
            <PurpleBox fullPadding align="flex-end" justify="center">
              <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="1295 kr" />
            </PurpleBox>
          </Box>
          <Box fullWidth direction="row" align="center" top spacing={'17px'} gap="80px">
            <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Attendee" />
            <Link onClick={() => setIsPartnerSelected(true)}>Add partner</Link>
          </Box>
        </Box>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <DarkBlueBox fullWidth>
            <Box fullWidth fullPadding spacing="25px">
              <Box fullWidth bottom spacing={'20px'}>
                <Controller
                  control={formProps.control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                      labelText="Name"
                      placeholder="Ange namn"
                    />
                  )}
                />
              </Box>
              <Box fullWidth bottom spacing={'20px'}>
                <Controller
                  control={formProps.control}
                  name="phoneNumber"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                      labelText="Phone number"
                      placeholder="Ange mobilnummer"
                    />
                  )}
                />
              </Box>
              <Box fullWidth bottom spacing={'20px'}>
                <Controller
                  control={formProps.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      {...field}
                      value={field.value.toUpperCase()}
                      fullWidth
                      fieldState={fieldState}
                      labelText="E-mail"
                      labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                      placeholder="Ange email"
                    />
                  )}
                />
              </Box>
              <Box fullWidth bottom spacing={'20px'} gap="10px">
                <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Role" />
                <Box fullWidth direction="row" align="center" justify="space-between">
                  <PurpleButton
                    isSelected={attendeeRole !== '' && attendeeRole !== 'FOLLOWER'}
                    width="415px"
                    height="40px"
                    align="center"
                    justify="center"
                    onClick={() => setAttendeeRole(attendeeRole === 'LEADER' ? '' : 'LEADER')}
                  >
                    <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Leader" />
                  </PurpleButton>
                  <PurpleButton
                    isSelected={attendeeRole !== '' && attendeeRole !== 'LEADER'}
                    width="415px"
                    height="40px"
                    align="center"
                    justify="center"
                    onClick={() => setAttendeeRole(attendeeRole === 'FOLLOWER' ? '' : 'FOLLOWER')}
                  >
                    <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Follower" />
                  </PurpleButton>
                </Box>
              </Box>
            </Box>
          </DarkBlueBox>
          {isPartnerSelected && (
            <>
              <Box fullWidth direction="row" align="center" fullPadding spacing={'25px'} gap="80px">
                <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Partner" />
                <Link onClick={() => setIsPartnerSelected(false)}>Remove</Link>
              </Box>
              <DarkBlueBox fullWidth>
                <Box fullWidth fullPadding spacing="25px">
                  <Box fullWidth bottom spacing={'20px'}>
                    <Controller
                      control={formProps.control}
                      name="partnerName"
                      render={({ field, fieldState }) => (
                        <TextFieldWithLabelForm
                          {...field}
                          value={field.value}
                          fullWidth
                          fieldState={fieldState}
                          labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                          labelText="Name"
                          placeholder="Ange namn"
                        />
                      )}
                    />
                  </Box>
                  <Box fullWidth bottom spacing={'20px'}>
                    <Controller
                      control={formProps.control}
                      name="partnerPhoneNumber"
                      render={({ field, fieldState }) => (
                        <TextFieldWithLabelForm
                          {...field}
                          value={field.value}
                          fullWidth
                          fieldState={fieldState}
                          labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                          labelText="Phone number"
                          placeholder="Ange mobilnummer"
                        />
                      )}
                    />
                  </Box>
                  <Box fullWidth bottom spacing={'20px'}>
                    <Controller
                      control={formProps.control}
                      name="partnerEmail"
                      render={({ field, fieldState }) => (
                        <TextFieldWithLabelForm
                          {...field}
                          value={field.value.toUpperCase()}
                          fullWidth
                          fieldState={fieldState}
                          labelText="E-mail"
                          labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                          placeholder="Ange email"
                        />
                      )}
                    />
                  </Box>
                  <Box fullWidth bottom spacing={'20px'} gap="10px">
                    <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Role" />
                    <Box fullWidth direction="row" align="center" justify="space-between">
                      <PurpleButton
                        isSelected={partnerRole !== '' && partnerRole !== 'FOLLOWER'}
                        width="415px"
                        height="40px"
                        align="center"
                        justify="center"
                        onClick={() => setPartnerRole(partnerRole === 'LEADER' ? '' : 'LEADER')}
                      >
                        <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Leader" />
                      </PurpleButton>
                      <PurpleButton
                        isSelected={partnerRole !== '' && partnerRole !== 'LEADER'}
                        width="415px"
                        height="40px"
                        align="center"
                        justify="center"
                        onClick={() => setPartnerRole(partnerRole === 'FOLLOWER' ? '' : 'FOLLOWER')}
                      >
                        <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Follower" />
                      </PurpleButton>
                    </Box>
                  </Box>
                </Box>
              </DarkBlueBox>
            </>
          )}
          <Box fullWidth fullPadding spacing={'25px'}>
            <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Discount code" />
          </Box>
          <DarkBlueBox fullWidth>
            <Box fullWidth fullPadding spacing="25px">
              <Box fullWidth bottom spacing={'20px'}>
                <Controller
                  control={formProps.control}
                  name="discountCode"
                  render={({ field, fieldState }) => (
                    <TextFieldWithLabelForm
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      labelTextKey={DeprecatedTexts.componentsAppMenuMenuOnboarding}
                      labelText="Code"
                      placeholder="Enter discount code"
                    />
                  )}
                />
              </Box>
              <Link onClick={() => console.log('Spara kod')}>Enter code</Link>
            </Box>
          </DarkBlueBox>
          <Box fullWidth fullPadding spacing={'25px'}>
            <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Payment" />
          </Box>
          <DarkBlueBox fullWidth>
            <Box fullWidth direction="row" fullPadding spacing="25px" gap="20px">
              <PurpleButton
                fullWidth
                direction="row"
                align="center"
                justify="space-around"
                isSelected={paymentMethodType === 'SWISH'}
                onClick={() => setPaymentMethodType('SWISH')}
              >
                <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Swish" />
                {/**  <img src={swish} /> **/}
              </PurpleButton>
              <PurpleButton
                fullWidth
                direction="row"
                align="center"
                justify="space-around"
                isSelected={paymentMethodType === 'CARD'}
                onClick={() => setPaymentMethodType('CARD')}
              >
                <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Card" />
                {/**  <img src={visa} /> **/}
              </PurpleButton>
              <PurpleButton
                fullWidth
                direction="row"
                align="center"
                justify="space-around"
                isSelected={paymentMethodType === 'APPLE_PAY'}
                onClick={() => setPaymentMethodType('APPLE_PAY')}
              >
                <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Apple Pay" />
                {/**  <img src={applePay} /> **/}
              </PurpleButton>
            </Box>
          </DarkBlueBox>
          <Box fullWidth align="center">
            <CheckoutButton onClick={formProps.handleSubmit(onSubmit)} align="center" justify="center">
              <FigmaText textKey={DeprecatedTexts.componentsAppMenuMenuOnboarding} text="Go to checkout" />
            </CheckoutButton>
          </Box>
        </form>
      </FormContainer>
    </Container>
  )
}

const Container = styled(Box)`
  background: black;
`

const FormContainer = styled(Box)`
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 900px;
  background: rgba(6, 7, 37, 1);
`

const PurpleBox = styled(Box)`
  width: 104px;
  height: 40px;
  background: linear-gradient(270deg, rgba(254, 99, 217, 0.4) 0%, rgba(254, 99, 217, 0) 100%);
`

const Link = styled(Box)`
  cursor: pointer;
  color: #fe63d9;
  font-size: 14px;
  text-decoration: underline;
`

const DarkBlueBox = styled(Box)`
  background: linear-gradient(
    180deg,
    rgba(34, 8, 175, 0.4) 18.18%,
    rgba(14, 3, 73, 0.32) 59.09%,
    rgba(34, 8, 175, 0.4) 100%
  );
  border-radius: ${BorderRadixes.moderate};
`

const PurpleButton = styled(Box)<{ width?: string; height?: string; isSelected?: boolean }>`
  width: ${({ width }) => (width ? width : '280px')};
  height: ${({ height }) => (height ? height : '45px')};
  cursor: pointer;
  border-radius: ${BorderRadixes.small};
  border: 1px solid rgba(118, 23, 208, 1);
  box-shadow: ${({ isSelected }) => isSelected && '0px 4px 16px 0px rgba(199, 0, 127, 0.4)'};
  background: ${({ isSelected }) => (isSelected ? 'rgba(103, 31, 117, 0.50)' : 'transparent')};
`

const CheckoutButton = styled(Box)`
  width: 315px;
  height: 50px;
  cursor: pointer;
  margin-top: 15px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(45, 10, 235, 0.5);
  background: linear-gradient(85deg, #2d0aeb 0%, #7617d0 99.12%);
`

export default CourseRegistrationForm
