import * as auth from 'firebase/auth'
import React, { useContext } from 'react'
import { User } from '../../../pure-js/types/User'
import { StorageKey } from './useLocalStorageItemHelper'
import { useSessionState } from './useSessionState'
import { useStorageItem } from './useStorageItem'
import { OnboardingChapter } from '../../../pure-js/enums/OnboardingChapter'

export type State = {
  user: Partial<User> // Updated to allow empty user initially
  firebaseUser?: auth.User
  isLoggedIn: boolean // Indicates whether the user is logged in
  shouldShowExpandedMenu?: boolean
  settingsTabIndex?: number
  currentOnboardingChapter?: OnboardingChapter | null
}

export const DEFAULT_STATE: State = {
  user: {}, // Empty user initially
  isLoggedIn: false, // Not logged in by default
  shouldShowExpandedMenu: false,
  firebaseUser: undefined
}

export type AppContext = {
  state: State
  setState: (newState: Partial<State> | ((prevState: State) => Partial<State>)) => void // Allow partial updates
}
export const Context = React.createContext<AppContext>({
  state: DEFAULT_STATE,
  setState: () => ({})
})

const IGNORED_KEYS: Array<keyof State> = []

export const useAppStateContext = () => {
  const { storageItem: state, setStorageItem: setState } = useStorageItem(StorageKey.STATE, DEFAULT_STATE, {
    ignoredKeys: IGNORED_KEYS
  })

  const session = useSessionState(state)

  return { state: { ...state, ...session } as State, setState }
}

export default function useAppState() {
  return useContext(Context)
}

export const useUserId = () => useAppState().state.user.id
