import React from 'react'
import CardGraph from './CardGraph'
import '../resources/css/DashboardCard.css'

interface DashboardCardProps {
  icon: React.ReactElement
  title: string
  value: string | number
  subtitle?: string
}

const DashboardCard: React.FC<DashboardCardProps> = ({ icon, title, value, subtitle = '' }) => {
  return (
    <div className="dashboard-card">
      <div className="dashboard-card-header">
        <div className="dashboard-card-icon">{icon}</div>
        <h3 className="dashboard-card-title">{title}</h3>
      </div>
      <div className="dashboard-card-content">
        <div className="dashboard-card-value">{value}</div>
        {subtitle && <p className="dashboard-card-subtitle">{subtitle}</p>}
      </div>
    </div>
  )
}

export default DashboardCard
