import React, { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'

import defaultUserIcon from '../resources/images/default_user.png' // Adjust the path as necessary
import { useLogout } from '../hooks/useLogout'
import useAppState from '../hooks/useAppState'
import { User } from '../../../pure-js/types/User'

const UserMenu = () => {
  const { state } = useAppState()
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const [userIcon, setUserIcon] = useState<string>(defaultUserIcon)
  const user = state.user as User

  const logout = useLogout()
  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen)
  }

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user?.photoURL) {
        setUserIcon(user.photoURL)
      } else {
        setUserIcon(defaultUserIcon)
      }
    })

    return () => unsubscribe()
  }, [])

  return (
    <div className="user-icon-container" onClick={toggleUserMenu}>
      <img src={userIcon} alt="User Icon" className="user-icon" />
      {isUserMenuOpen && (
        <div className="user-menu">
          <p>{user.displayName}</p>
          <p>{user.email}</p>
          <button
            onClick={logout}
            style={{
              padding: '10px 20px',
              fontSize: '1rem',
              backgroundColor: '#f44336',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'
            }}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  )
}

export default UserMenu
