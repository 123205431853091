import React, { useEffect, useState } from 'react'
import '../resources/css/Dashboard.css'
import DashboardCard from '../components/DashboardCard'
import userIcon from '../resources/images/User.png' // Adjust the path as necessary
import moneyIcon from '../resources/images/Money.png' // Adjust the path as necessary
import ActivityList from '../components/AcitivityList'
import Menu from '../components/Menu'
import { getActivitiesForClient, getUser } from '../libs/DBApiHandler'
import { Activity, Enrollment } from '../../../pure-js/types/GrooverTypes'
import { getEnrollmentsForClient, getEnrollmentsForPeriod } from '../libs/CloudFunctionsApiHandler'
import { describe, it, expect } from 'vitest'

import UserMenu from '../components/UserMenu'
import useAppState from '../hooks/useAppState'
import { GetEnrollmentsForPeriodRequest } from '../../../pure-js/types/QueryParamTypes'
import { getAuth } from 'firebase/auth'
import { User } from '../../../pure-js/types/User'
import {
  getAllEnrollmentsCount,
  getAllPaidRevenueForRegistration,
  getAllRevenueForRegistration,
  getPaidRegistrationRatio
} from '../utils/enrollmentUtils'

const Dashboard: React.FC = () => {
  const { state } = useAppState()

  const [user, setUser] = useState<any>(null) // Replace `any` with your user type if available
  const [clientId, setClientId] = useState<string>('')
  const [error, setError] = useState<Error | null>(null)
  const [loading, setLoading] = useState(false)
  const [authUser, setAuthUser] = useState<any>(null)
  const [enrollments, setEnrollments] = useState<Enrollment[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [activitiesLoaded, setActivitiesLoaded] = useState<boolean>(false)

  const auth = getAuth()
  const currentUser = auth.currentUser

  // this is not fetching data now. It just sets connection and observe the database
  // check activities.isLoading and activities.status to see if data is fetched
  // then you can use activities.data
  const _activities = getActivitiesForClient(clientId)

  useEffect(() => {
    if (!clientId) return
    if (!_activities.isLoading && _activities.status == 'success') {
      setActivities(_activities.data as Activity[])
      setActivitiesLoaded(true)
      console.log('set registrations', _activities.data)
    }
  }, [clientId, _activities])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setAuthUser(user)
    })
    return () => unsubscribe() // Cleanup the listener on unmount
  }, [auth])

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser?.uid) {
        console.warn('No current user found')
        return
      }

      setLoading(true) // Set loading to true at the beginning

      try {
        // Fetch user data
        const _user = await getUser(currentUser.uid)
        console.log('User: ', _user)
        setUser(_user)

        // Extract clientIds
        const userClientIds = _user?.authCustomClaims?.clientIds || []
        console.log('ClientIds: ', userClientIds)

        if (userClientIds.length > 0) {
          // Fetch enrollments for the first clientId
          setClientId(userClientIds[0])
          const req = { clientId: userClientIds[0] } // Replace with your actual request shape
          const _enrollments = await getEnrollmentsForClient(req, state) // Call your backend function

          setEnrollments(_enrollments.filter((x) => x.status != 'deleted'))
          console.log('Enrollments: ', _enrollments)
        }
      } catch (err) {
        console.error('Error fetching data: ', err)
        setError(err as Error)
      } finally {
        setLoading(false) // Set loading to false at the end
      }
    }

    if (authUser) {
      fetchData()
    }
  }, [authUser, currentUser?.uid, state]) // observe authUser, currentUser.uid, and state

  return (
    <div className="mainContainer">
      {/* <Menu activeItem="dashboard" /> */}{' '}
      {/* TODO: Add a CSS that changes the design when in mobile (top menu instead) */}
      <UserMenu />
      <div className="PageContainer">
        <div className="dashboard-container">
          <div className="dashboard-header">
            <div className="dashboard-period">
              <span>Period</span>
              <select>
                <option>VT 2025</option>
              </select>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner">Loading...</div>
          ) : (
            <>
              <div className="dashboard-cards">
                <DashboardCard
                  icon={<img src={userIcon} alt="User Icon" />}
                  title="Anmälningar"
                  value={getAllEnrollmentsCount(enrollments)}
                />
                <DashboardCard
                  icon={<img src={moneyIcon} alt="Money Icon" />}
                  title="Totalt värde"
                  value={getAllRevenueForRegistration(enrollments, true) + ' SEK'}
                  subtitle={`Inkl. ej godkända: ${getAllRevenueForRegistration(enrollments, false)} SEK`}
                />
                <DashboardCard
                  icon={<img src={moneyIcon} alt="Money Icon" />}
                  title="Inbetalt"
                  value={`${getAllPaidRevenueForRegistration(enrollments)} SEK`}
                  subtitle={`${getPaidRegistrationRatio(enrollments)}`}
                />
              </div>
              <div className="coursesContainer">
                <ActivityList activities={activities as Activity[]} activitiesLoaded={activitiesLoaded} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Dashboard
