import { AuthProvider, UserCredential } from 'firebase/auth'
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITION_URL } from '../../../pure-js/libs/Consts'
import { PreferredLoginMethod, SignInState, SignInSteps } from '../../../pure-js/types/SignInTypes'
import { State } from '../hooks/useAppState'
import { addQueryParamsToUrl } from '../../../pure-js/libs/QueryparamHelper'
import { ACTION_CODE_SETTINGS } from './ActionCodeSettings'
import * as FirebaseHelper from './FirebaseHelper'
import { GoogleAuthProvider, MicrosoftAuthProvider } from './SignInProviders'

export type SignInPageProps = {
  onLogin?: (state: State) => unknown
  signInState?: Partial<SignInState>
}

export const getProviderForProviderId = (providerId: string): AuthProvider | undefined => {
  if (providerId === GoogleAuthProvider.providerId) return GoogleAuthProvider
  if (providerId === MicrosoftAuthProvider.providerId) return MicrosoftAuthProvider
}

export function signInWithEmailLink(signInState: SignInState): Promise<UserCredential> {
  const { email } = signInState.data
  return FirebaseHelper.signInWithEmailLink(email, window.location.href).then((userCredential) => {
    return onSignInWithEmailLink(userCredential)
  })
}

export function onSignInWithEmailLink(
  userCredential: UserCredential,
  invitationToken?: string,
  _window: Window = window
) {
  let url = ACTION_CODE_SETTINGS.url
  if (invitationToken) url = addQueryParamsToUrl(url, { invitationToken: invitationToken as string })
  _window.location.href = url

  return userCredential
}

export const DEFAULT_SIGN_IN_STATE: SignInState = {
  data: {
    email: ''
  },
  preferredLoginMethod: PreferredLoginMethod.MAIL,
  step: SignInSteps.LANDING
}

const InAppBrowsers = ['Instagram', 'FBAN', 'FBAV', 'Twitter', 'Pinterest', 'LinkedInApp', 'Slack']

export const isInAppBrowser = () => {
  const ua = navigator.userAgent || navigator.vendor
  return InAppBrowsers.some((app) => ua.includes(app))
}
