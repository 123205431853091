import { signOut } from 'firebase/auth'
import { auth } from '../libs/Firebase'
import useAppState, { DEFAULT_STATE } from './useAppState'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection'
import Cookies from 'js-cookie'

export function useLogout(onLogout?: () => void) {
  const { setState } = useAppState()

  return () => {
    onLogout?.()
    setState(DEFAULT_STATE)
    Cookies.remove('access_token') // Remove the token
    return signOut(auth).catch(onUnhandledPromiseRejection)
  }
}
