/* eslint-disable camelcase */
import { jwtDecode } from 'jwt-decode'
import axios from 'axios'
import Cookies from 'js-cookie'

export const isTokenValid = (token: string): boolean => {
  try {
    const decoded: { exp: number } = jwtDecode(token)
    const currentTime = Math.floor(Date.now() / 1000)
    return decoded.exp > currentTime // Token is valid if current time is less than expiration time
  } catch (error) {
    console.error('Invalid token:', error)
    return false
  }
}

export const refreshAccessToken = async (refreshToken: string, apiKey: string) => {
  try {
    const response = await axios.post(`https://securetoken.googleapis.com/v1/token?key=${apiKey}`, {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    })

    const { id_token, refresh_token, expires_in } = response.data

    // Save the new access and refresh tokens
    Cookies.set('access_token', id_token, { secure: true, sameSite: 'Strict' })
    Cookies.set('refresh_token', refresh_token, { secure: true, sameSite: 'Strict' })

    return {
      accessToken: id_token,
      refreshToken: refresh_token,
      expiresIn: expires_in
    }
  } catch (error) {
    console.error('Error refreshing token:', error)
    throw error
  }
}

export const getValidAccessToken = async (apiKey: string) => {
  const accessToken = Cookies.get('access_token')
  const refreshToken = Cookies.get('refresh_token')

  if (accessToken && isTokenValid(accessToken)) {
    // Token is valid
    return accessToken
  }

  if (refreshToken) {
    // Refresh the token
    const refreshedTokens = await refreshAccessToken(refreshToken, apiKey)
    return refreshedTokens.accessToken
  }

  throw new Error('User is not authenticated.')
}
