import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate from react-router-dom
import { debounce } from 'lodash' // Import debounce from lodash
import '../resources/css/ActivityList.css' // Import CSS
import { Activity } from '../../../pure-js/types/GrooverTypes'

interface ActivityListProps {
  activities: Activity[]
  activitiesLoaded: boolean
}

/* Main Component */
const ActivityList: React.FC<ActivityListProps> = ({ activities, activitiesLoaded }) => {
  const [filterText, setFilterText] = useState('')
  const [filteredActivities, setFilteredActivities] = useState(activities)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate() // Initialize useNavigate

  const highlightText = (text, highlight) => {
    if (!highlight) return text
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: '#ffeb3b', color: 'black' }}>
          {part}
        </span> // Changed background color
      ) : (
        part
      )
    )
  }

  const debouncedFilter = useCallback(
    debounce((text) => {
      setFilteredActivities(
        activities.filter((registration) => registration.name.toLowerCase().includes(text.toLowerCase()))
      )
      setLoading(false)
    }, 300),
    [activities]
  )

  useEffect(() => {
    setLoading(true) // Start loading immediately when the filter text changes
    // avoid filtering if activities are not loaded
    if (activitiesLoaded) debouncedFilter(filterText)
    return () => {
      debouncedFilter.cancel()
    }
  }, [filterText, debouncedFilter])

  // Group registrations by style
  const groupedregistrations: { [key: string]: Activity[] } = filteredActivities.reduce(
    (acc: { [key: string]: Activity[] }, registration) => {
      if (!acc[registration.style]) {
        acc[registration.style] = []
      }
      acc[registration.style].push(registration)
      return acc
    },
    {}
  )

  const handleRegistrationClick = (activity: Activity) => {
    navigate(`/enrollments/${activity.clientId}/${activity.id}`, { state: { registration: activity } }) // Navigate to the activity page with enrollments
  }

  return (
    <div className="registration-list">
      <input
        type="text"
        placeholder="Filtrera"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        className={`filter-input ${Object.keys(groupedregistrations).length === 0 && !loading ? 'no-results-border' : ''}`}
      />
      {loading ? (
        <div className="loading">
          <div className="spinner"></div>
        </div>
      ) : Object.keys(groupedregistrations).length === 0 ? (
        <div className="no-results">No results available</div>
      ) : (
        Object.keys(groupedregistrations).map((style) => (
          <div key={style}>
            <h2>{style}</h2>
            {groupedregistrations[style].map((registration) => (
              <div
                key={registration.id}
                className="registration-item"
                onClick={() => handleRegistrationClick(registration)}
              >
                <div className="registration-title">{highlightText(registration.name, filterText)}</div>
                <div className="registration-time">{registration.startDate}</div>
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  )
}

export default ActivityList
