import React from 'react'
import { getValidAccessToken } from '../utils/loginHelper'
import { useLogout } from './useLogout'
import useAppState from './useAppState'
import { FIREBASE_CONFIG } from '../../../pure-js/libs/Consts'

export const OnMountApp = () => {
  useOnMountApp()
  const logout = useLogout()
  const { setState } = useAppState()
  React.useEffect(() => {
    const initializeAuth = async () => {
      try {
        const validToken = await getValidAccessToken(FIREBASE_CONFIG.apiKey)
        setState((prevState) => ({
          ...prevState,
          isLoggedIn: true,
          firebaseUser: validToken // Optional: Decode and save user details
        }))
      } catch (error) {
        console.error('Authentication initialization failed:', error)
        logout() // Clear tokens and state if initialization fails
      }
    }

    initializeAuth()
  }, [])
  return null
}

export const useOnMountApp = () => {
  return undefined
}
