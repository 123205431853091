import styled from '@emotion/styled'
import { smallBorderRadius } from '../enums/BorderRadixesDeprecated'
import { smallSpacing } from '../enums/Spacings'
import { ImageKey, TextKey } from '../../../pure-js/types/Antiloop'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

export default function ContinueWithButton({
  onLogin,
  textKey,
  imageKey,
  text
}: {
  onLogin?: () => void
  textKey: TextKey
  imageKey?: ImageKey
  text?: string
}) {
  return (
    <Button
      fullWidth
      onClick={onLogin}
      spacing={smallSpacing}
      buttonType="secondary"
      size="small"
      color={'white' as any}
    >
      <ContinueWithButtonContainer direction="row" align="center" justify="center" fullWidth>
        {imageKey && <FigmaImageContainer imageKey={imageKey} width={32} />}
        <Box left spacing={smallSpacing}>
          <FigmaTextWithStyle type="button1" textKey={textKey} text={text} />
        </Box>
      </ContinueWithButtonContainer>
    </Button>
  )
}

const ContinueWithButtonContainer = styled(Box)`
  border-radius: ${smallBorderRadius};
  cursor: pointer;
  padding: 10px;
`
