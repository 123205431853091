import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { RootPage } from '../pages/SignInPage'
import SandboxPage from './SandboxPage'

const NavigationPublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePath.ROOT} Component={RootPage} />
      <Route path={RoutePath.SANDBOX} Component={SandboxPage} />
      <Route path="*" element={<Navigate to={RoutePath.ROOT} />} />
    </Routes>
  )
}

export default NavigationPublicRoutes
