import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { Collections } from '../../../pure-js/libs/Collections.js'
import { mapQueryResponse } from '../../../pure-js/libs/FirebaseStorageClientBaseHelper.js'
import { PartialUser, User } from '../../../pure-js/types/User.js'
import { Id } from '../../../pure-js/types/types.js'
import { db } from './Firebase.js'
import { Activity, Client, Enrollment, EnrollmentUserRequest } from '../../../pure-js/types/GrooverTypes.js'
import { useFirestoreQuery } from '../hooks/useFirestoreQuery.js'
import courseUserRequests from '../resources/mockdata/courseUserRequest.json' // Temporarily added for testing purposes

export const updateUser = (user: PartialUser): Promise<unknown> =>
  setDoc(doc(db, `${Collections.USERS}/${user.id}`), user, { merge: true })

export const getUser = (id: Id): Promise<User | undefined> =>
  getDoc(doc(db, `${Collections.USERS}/${id}`)).then(mapQueryResponse)

export const upsertActivity = (o: Activity): Promise<unknown> =>
  setDoc(doc(db, `${Collections.ACTIVITIES}/${o.id}`), o, { merge: true })

export const getActivity = (id: Id): Promise<Activity | undefined> =>
  getDoc(doc(db, `${Collections.ACTIVITIES}/${id}`)).then(mapQueryResponse)

export const upsertClient = (o: Client): Promise<unknown> =>
  setDoc(doc(db, `${Collections.CLIENTS}/${o.id}`), o, { merge: true })

export const getClient = (id: Id): Promise<Client | undefined> =>
  getDoc(doc(db, `${Collections.CLIENTS}/${id}`)).then(mapQueryResponse)

export const upsertEnrollment = (o: Enrollment): Promise<unknown> =>
  setDoc(doc(db, `${Collections.ENROLLMENTS}/${o.id}`), o, { merge: true })

export const getEnrollment = (id: Id): Promise<Client | undefined> =>
  getDoc(doc(db, `${Collections.ENROLLMENTS}/${id}`)).then(mapQueryResponse)

export const getActivitiesForClient = (clientId: string) =>
  useFirestoreQuery(query(collection(db, `${Collections.ACTIVITIES}`), where('clientId', '==', clientId)), {
    fnName: 'getActivitiesForClient',
    enabled: !!clientId // run query only when clientId is available
  })

export const getEnrollmentsByRegistrationId = async (): Promise<EnrollmentUserRequest[]> => {
  // Read the entire JSON file and return the data
  return courseUserRequests as EnrollmentUserRequest[]
}
