import { Enrollment } from '../../../pure-js/types/GrooverTypes'

export const formatPayment = (payment?: number) => {
  if (payment === undefined || payment === null) {
    return 'N/A'
  }
  return Math.round(payment / 100)
}

// Return all revenue or only confirmed revenue for a registration
export const getAllRevenueForRegistration = (enrollments: Enrollment[], onlyConfirmed: boolean) => {
  const filterEnrollments = enrollments.filter(
    (enrollment) => enrollment.payments && (!onlyConfirmed || enrollment.status === 'confirmed')
  )

  const totalRevenue = filterEnrollments.reduce((total, enrollment) => {
    const enrollmentRevenue = enrollment.payments.reduce(
      (paymentTotal, payment) => paymentTotal + payment.totalAmount,
      0
    )
    return total + enrollmentRevenue
  }, 0)

  return formatPayment(totalRevenue)
}

export const getAllPaidRevenueForRegistration = (enrollments: Enrollment[]) => {
  return formatPayment(
    enrollments.reduce((total, enrollment) => {
      return total + getEnrollmentPaidAmount(enrollment)
    }, 0)
  )
}

export const getAllUnPaidRevenueForRegistration = (enrollments: Enrollment[]) => {
  const getTotalPrice = enrollments.reduce((total, enrollment) => {
    return total + getEnrollmentPrice(enrollment)
  }, 0)
  const getTotalPaid = enrollments.reduce((total, enrollment) => {
    return total + getEnrollmentPaidAmount(enrollment)
  }, 0)
  return formatPayment(getTotalPrice - getTotalPaid)
}

export const getPaidRegistrationRatio = (enrollments: Enrollment[]): string => {
  // Calculate the totalAmount for paid registrations (confirmed payments)
  const totalPaidAmount = enrollments.reduce((sum, enrollment) => {
    const confirmedPayments = enrollment.payments.filter((payment) => payment.type === 'pay')
    const confirmedAmount = confirmedPayments.reduce((paymentSum, payment) => paymentSum + payment.totalAmount, 0)
    return sum + confirmedAmount
  }, 0)

  // Calculate the totalAmount for all confirmed registrations
  const totalAllAmount = enrollments.reduce((sum, enrollment) => {
    if (enrollment.status === 'confirmed') {
      const allPaymentsAmount = enrollment.payments.reduce((paymentSum, payment) => paymentSum + payment.totalAmount, 0)
      return sum + allPaymentsAmount
    }
    return sum
  }, 0)

  // Handle edge case where totalAllAmount is 0
  if (totalAllAmount === 0) {
    return '0%'
  }

  // Calculate the ratio and format as a percentage
  const ratio = (totalPaidAmount / totalAllAmount) * 100
  return `${ratio.toFixed(0)}%`
}

export const getAllEnrollmentsCount = (enrollments: Enrollment[]): number => {
  return enrollments.reduce((total, attendee) => total + 1 + (attendee.partnerUser ? 1 : 0), 0)
}

export const getEnrollmentPrice = (enrollment: Enrollment): number =>
  enrollment.priceDetails.reduce((total, pd) => total + pd.finalPrice, 0)

export const getEnrollmentPaidAmount = (enrollment: Enrollment): number =>
  enrollment.payments?.reduce((total, payment) => {
    return total + payment.totalAmount
  }, 0)

export const isEnrollmentPaid = (enrollment: Enrollment): boolean =>
  getEnrollmentPrice(enrollment) <= getEnrollmentPaidAmount(enrollment)

export const getEnrollmentCurrency = (enrollment: Enrollment): string => 'SEK' // get from enrollment
