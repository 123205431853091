import React from 'react'
import { tinySpacing } from '../enums/Spacings'
import FigmaText from './FigmaText'
import Box from './Box'
import TextFieldForm, { TextFieldFormProps } from './TextFieldFormDeprecated'
import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'

const TextFieldWithLabelFormComponent = (props: TextFieldFormProps, ref) => {
  const { labelTextKey, fullWidth, labelText, ...textFieldProps } = props
  return (
    <Box fullWidth={fullWidth}>
      {labelTextKey && <FigmaText textKey={labelTextKey} text={labelText} />}
      <Box top spacing={tinySpacing} fullWidth>
        <StyledTextFieldForm {...textFieldProps} ref={ref} fullWidth />
      </Box>
    </Box>
  )
}

const StyledTextFieldForm = styled(TextFieldForm)`
  background-color: transparent;
  border: 1px solid rgba(118, 23, 208, 1);
  border-radius: ${BorderRadixes.small};

  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    color: white;
  }
`

export const TextFieldWithLabelForm = React.forwardRef(TextFieldWithLabelFormComponent)
